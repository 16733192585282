// body {
//   width: 100vw;
//   height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   svg {
//     overflow: visible;
//   }
// }

.wrapper {
  width: 100px !important;
  // height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.d-spinner {
  $color-one: #ff5757;
  $color-two: #ff8585;
  $color-three: #ffadad;
  $color-four: #ffd6d6;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0, 1, 0.3, 1);
  animation-direction: normal;
  animation-iteration-count: infinite;
  transform-origin: left bottom;

  &.d-spinner__one {
    animation-name: dSpinnerOne;
    fill: $color-one;
  }

  &.d-spinner__two {
    animation-name: dSpinnerTwo;
    fill: $color-two;
  }

  &.d-spinner__three {
    animation-name: dSpinnerThree;
    fill: $color-three;
  }

  &.d-spinner__four {
    animation-name: dSpinnerFour;
    fill: $color-four;
  }

  @keyframes dSpinnerOne {
    0% {
      opacity: 0;
      fill: lighten($color-one, 20%);
      transform: rotateZ(-65deg) scale(0.6);
    }

    7% {
      opacity: 1;
      fill: lighten($color-one, 20%);
      transform: rotateZ(0) scale(1);
    }

    57% {
      animation-timing-function: cubic-bezier(0, 0, 0, 1);
      fill: $color-one;
    }

    74% {
      opacity: 1;
      transform: rotateZ(0) scale(1);
      animation-timing-function: cubic-bezier(0, 0, 1, 0);
    }

    83% {
      opacity: 0;
      transform: rotateZ(45deg) scale(0.61);
    }

    100% {
      opacity: 0;
      transform: rotateZ(45deg) scale(0.61);
    }
  }

  @keyframes dSpinnerTwo {
    0% {
      opacity: 0;
      transform: rotateZ(-65deg) scale(0.6);
    }

    3% {
      opacity: 0;
      fill: lighten($color-two, 20%);
      transform: rotateZ(-65deg) scale(0.6);
    }

    10% {
      opacity: 1;
      fill: lighten($color-two, 20%);
      transform: rotateZ(0) scale(1);
    }

    60% {
      animation-timing-function: cubic-bezier(0, 0, 0, 1);
      fill: $color-two;
    }

    71% {
      opacity: 1;
      transform: rotateZ(0) scale(1);
      animation-timing-function: cubic-bezier(0, 0, 1, 0);
    }

    79% {
      opacity: 0;
      transform: rotateZ(45deg) scale(0.61);
    }

    100% {
      opacity: 0;
      transform: rotateZ(45deg) scale(0.61);
    }
  }

  @keyframes dSpinnerThree {
    0% {
      opacity: 0;
      transform: rotateZ(-65deg) scale(0.6);
    }

    6% {
      opacity: 0;
      fill: lighten($color-three, 20%);
      transform: rotateZ(-65deg) scale(0.6);
    }

    13% {
      opacity: 1;
      fill: lighten($color-three, 20%);
      transform: rotateZ(0) scale(1);
    }

    63% {
      fill: $color-three;
      animation-timing-function: cubic-bezier(0, 0, 0, 1);
    }

    68% {
      opacity: 1;
      transform: rotateZ(0) scale(1);
      animation-timing-function: cubic-bezier(0, 0, 1, 0);
    }

    76% {
      opacity: 0;
      transform: rotateZ(45deg) scale(0.61);
    }

    100% {
      opacity: 0;
      transform: rotateZ(45deg) scale(0.61);
    }
  }

  @keyframes dSpinnerFour {
    0% {
      opacity: 0;
      transform: rotateZ(-65deg) scale(0.6);
    }

    9% {
      opacity: 0;
      fill: lighten($color-four, 20%);
      transform: rotateZ(-65deg) scale(0.6);
    }

    16% {
      opacity: 1;
      fill: lighten($color-four, 20%);
      transform: rotateZ(0) scale(1);
    }

    64% {
      fill: $color-four;
      animation-timing-function: cubic-bezier(0, 0, 0, 1);
    }

    65% {
      opacity: 1;
      transform: rotateZ(0) scale(1);
      animation-timing-function: cubic-bezier(0, 0, 1, 0);
    }

    73% {
      opacity: 0;
      transform: rotateZ(45deg) scale(0.61);
    }

    100% {
      opacity: 0;
      transform: rotateZ(45deg) scale(0.61);
    }
  }
}
