/* * {
    font-family: "Helvetica Neue", Helvetica;
    font-size: 15px;
    font-variant: normal;
    padding: 0;
    margin: 0;
  }
   */

/* body {
    background: #E6EBF1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
  } */

#payment-form {
  /* width: 480px;
    margin: 20px 0; */
  /* font-family: "Helvetica Neue", Helvetica; */
  font-family: "Roboto", Roboto;
  font-size: 15px;
  font-variant: normal;
  padding: 0;
  margin: 0;
}

.group {
  background: white;
  box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  /* margin-bottom: 20px; */
}

.box2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.card-label {
  position: relative;
  color: #1c1c1c;
  font-weight: 300;
  /* height: 40px; */
  /* line-height: 40px; */
  /* margin-left: 20px; */
  display: flex;
  flex-direction: column;
}

.group label:not(:last-child) {
  border-bottom: 1px solid #f0f5fa;
}

.card-label > span {
  width: 100px;
  /* text-align: right; */
  /* margin-right: 30px; */
}

/* .card-label > span.brand {
  width: 30px;
} */

.small-box {
  display: flex;
  width: 40%;
}

.card-helper-text {
  margin-bottom: 5px;
  font-weight: 600;
  color: rgb(61, 61, 61);
}

.helper-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-wrapper {
  display: flex;
  margin-bottom: 5px;
}

.card-cont {
  width: 40px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.field {
  background: transparent;
  font-weight: 300;
  border: 0;
  color: #31325f;
  outline: none;
  flex: 1;
  padding-right: 10px;
  padding-left: 10px;
  cursor: text;
  border: 1px solid black;
}

.card-field {
  border: 1px solid gray;
  width: 100%;
  padding: 0px 10px !important;
  border-radius: 10px;
}

.field::-webkit-input-placeholder {
  color: #1c1c1c;
}

.field::-moz-placeholder {
  color: #1c1c1c;
}

.subscribe-btn {
  width: 100%;
  margin-top: 20px;
  /* float: left;
    display: block;
    background: #666EE8;
    color: white;
    box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.10), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: 0;
    font-size: 15px;
    font-weight: 400;
    height: 40px;
    line-height: 38px;
    outline: none; */
}

.subscribe-btn:focus {
  background: #555abf;
}

.subscribe-btn:active {
  background: #43458b;
}

.outcome {
  float: left;
  width: 100%;
  padding-top: 8px;
  min-height: 24px;
  text-align: center;
}

.success,
.error {
  display: none;
  font-size: 13px;
}

.success.visible,
.error.visible {
  display: inline;
}

.error {
  color: #e4584c;
}

.success {
  color: #666ee8;
}

.success .token {
  font-weight: 500;
  font-size: 13px;
}
