* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  height: 100%;
  width: 100%;
}
body {
  background-color: #f4f6f8;
  height: 100%;
  width: 100%;
}
a {
  text-decoration: none;
}

#root {
  height: 100%;
  width: 100%;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #ff5757;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #7a83c8;
}

.MuiOutlinedInput-root {
  border-radius: 13px;
  /* max-width: 400px; */
  font-size: 14px;
}
